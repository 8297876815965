import { map, orderBy, some } from "lodash";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  AddOutlined,
  CopyAllOutlined,
  DownloadOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  useGroup,
  AutoJoinDomain,
  useGroupControls,
  GroupCodeListItem,
  GroupDomain,
} from "features/Group";
import { FeatureHelpVideo } from "features/Support";
import { toastError } from "utils/toast.util";
import { downloadFile } from "utils/html.util";
import { fillParent, scrollingBox, footerFlex } from "utils/base-styles";
import Footer from "components/layout/Footer";
import PageTitle from "components/layout/PageTitle";

export default function GroupControlsPage() {
  const { palette } = useTheme();

  const { groupId, isGroupLoading } = useGroup();
  const {
    groupCodes,
    createGroupCode,
    isGroupControlsLoading,
    groupDomains,
    getGroupDomains,
  } = useGroupControls();

  const [verifyModal, setVerifyModal] = useState(null);
  const [showNew, setShowNew] = useState(false);

  const refreshTimer = useRef(null);

  async function handleCreateGroupCode() {
    const newCode = await createGroupCode();
    toast.success(`Created new Group Code: '${newCode.code}'`);
  }

  async function handleCopyVerificationCode(code) {
    try {
      await navigator.clipboard.writeText(code);
      toast.success(`verification code '${code}' was copied to clipboard.`);
    } catch (error) {
      toastError(`Unable to copy code '${code}' to clipboard.`);
    }
  }

  useEffect(() => {
    refreshTimer.current = setInterval(() => {
      getGroupDomains();
    }, 5 * 60 * 1000);
    return () => {
      clearInterval(refreshTimer.current);
      refreshTimer.current = null;
    };
  }, []);

  return (
    <Box
      sx={{
        ...fillParent,
        ...scrollingBox,
        ...footerFlex,
      }}
    >
      <Stack direction="column" pb={"50px"}>
        <PageTitle>
          Group Controls{" "}
          <FeatureHelpVideo
            videoId="1002947722"
            title="Tip: How to use group controls"
            showText
          />
        </PageTitle>
        <Typography variant="body2">
          Group controls are where you can setup alternative methods for users
          to join your group account and also to control access to different
          parts of Sooth's research tools for your users.
        </Typography>
        <Divider />
        <Typography id="group-codes" variant="h4">
          Group Codes
        </Typography>
        <Typography>
          Group codes are an easy way to let users join your group. You can
          generate a new code and give it out to the individuals you want to
          join your group.
        </Typography>
        <Typography>
          This method of adding users to your group offers flexibility, allowing
          you to control access by individual classrooms or manage user cycles
          from one semester to the next, putting you in the driver's seat.
        </Typography>
        <Typography>
          We have provided some easy-to-use communication materials to help you
          connect your users quickly.{" "}
          <Button
            variant="text"
            startIcon={<DownloadOutlined fontSize="inherit" />}
            onClick={() => {
              downloadFile(
                "/support/group-code-materials.zip",
                "Group Code Materials.zip"
              );
            }}
          >
            Download
          </Button>
        </Typography>
        <Alert severity="info">
          <Typography>
            All users will be added to the "Student" role when they join. You
            can adjust this later in{" "}
            <Link to="user-management">User Management</Link>
          </Typography>
        </Alert>
        <Alert severity="info">
          <Typography>
            Deleting a group code will not delete users who have joined your
            group with that code. It will, however, prevent anyone using that
            code from joining your group in the future.
          </Typography>
        </Alert>
        {!isGroupLoading && (
          <Fragment>
            <Box>
              <Button
                variant="outlined"
                startIcon={<AddOutlined fontSize="inherit" />}
                onClick={handleCreateGroupCode}
                disabled={isGroupControlsLoading}
              >
                Create Group Code
              </Button>
            </Box>
            <List
              sx={{
                pr: 50,
                "> li:not(:last-child)": {
                  borderBottom: `1px solid ${palette.divider}`,
                },
              }}
            >
              {groupCodes.map((groupCode) => (
                <GroupCodeListItem
                  groupCode={groupCode}
                  key={`group-code-${groupId}-${groupCode.id}`}
                />
              ))}
            </List>
          </Fragment>
        )}
        <Divider />
        <Typography id="auto-join-domains" variant="h4">
          Domain Auto-join
        </Typography>
        <Typography>
          This feature allows you to seamlessly populate your group with all
          your intended users. We use your group’s unique email domain(s) (e.g.
          @abcschool.org) to identify users and assign them to your group when
          they log in, including future users who don’t yet have an account.
          It’s easy to set up and all the magic happens in the background.
        </Typography>
        <Alert severity="warning">
          <Typography>
            This feature is only appropriate for your group{" "}
            <Typography component="span" sx={{ textDecoration: "underline" }}>
              if the following statement is true
            </Typography>
            :
          </Typography>
          <Box component="ul" mb={2}>
            <Typography component="li">
              You have purchased a group license that is intended to include
              everyone who shares the same email domain(s) (e.g. abcschool.org)
            </Typography>
          </Box>
          <Typography>
            If the above statement is{" "}
            <Typography component="span" sx={{ textDecoration: "underline" }}>
              not true
            </Typography>{" "}
            and there are large groups of people (relative to the size of your
            group subscription) who also use the same email domain (e.g.
            abcschool.org), you run the risk of extending licenses to users
            beyond your intended group. Please use Group Codes or Bulk Upload
            instead.
          </Typography>
        </Alert>
        <Alert severity="warning">
          <Typography>
            This step will require your coordination with your organization's IT
            department. Click "+ Add Domain" below to get started and follow the
            instructions. The steps required are designed to protect your
            organization as well as the integrity of your group subscription.
          </Typography>
        </Alert>
        <Alert severity="info">
          <Typography>
            All users will be added to the "Student" role when they join. You
            can adjust this later in{" "}
            <Link to="user-management">User Management</Link>
          </Typography>
        </Alert>
        <Alert severity="info">
          <List sx={{ listStyle: "disc", pl: 2, py: 0 }}>
            <ListItem sx={{ display: "list-item", pl: 0, py: 0 }}>
              <ListItemText>
                Include all email domains relevant to your organization. Your
                school may have different domains for staff and students (e.g.
                "student.abcschool.org" for students and "abcschool.org" for
                staff.)
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item", pl: 0, py: 0 }}>
              <ListItemText>
                Include all Single Sign-On email domains if these are different
                than your regular email domains (e.g. "abcschool.net".)
              </ListItemText>
            </ListItem>
          </List>
        </Alert>
        <Alert severity="info">
          <Typography>
            Deleting a domain will not remove users from your group. It will,
            however, no longer auto-join users with that domain to your group in
            the future.
          </Typography>
        </Alert>
        <Stack direction="row">
          <Button
            variant="outlined"
            startIcon={<AddOutlined fontSize="inherit" />}
            onClick={() => setShowNew(true)}
            disabled={showNew || isGroupControlsLoading}
          >
            Add Domain
          </Button>
          <IconButton
            onClick={() => getGroupDomains()}
            disabled={isGroupControlsLoading}
          >
            <Tooltip title="Refresh Domains">
              <RefreshOutlined fontSize="inherit" />
            </Tooltip>
          </IconButton>
        </Stack>
        <List
          sx={{
            pr: 25,
            "> li:not(:last-child)": {
              borderBottom: `1px solid ${palette.divider}`,
            },
          }}
        >
          {showNew && (
            <AutoJoinDomain
              groupDomain={
                new GroupDomain({
                  domain: "",
                  isVerified: false,
                })
              }
              isNewItem
              closeNew={() => setShowNew(false)}
              setVerifyModal={() => {}}
            />
          )}
          {map(orderBy(groupDomains, ["domain"]), (daj, index) => (
            <AutoJoinDomain
              groupDomain={daj}
              setVerifyModal={setVerifyModal}
              key={daj.id != null ? daj.id : `new-${index}`}
            />
          ))}
        </List>
        {verifyModal != null && (
          <Dialog
            open={verifyModal != null}
            onClose={() => setVerifyModal(null)}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle>How to verify {verifyModal.domain}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                In order to verify your domain, follow the steps below:
              </DialogContentText>
              <List sx={{ listStyle: "decimal", pl: 4 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText
                    disableTypography
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "baseline",
                    }}
                  >
                    Copy your verification code:{" "}
                    <Button
                      variant="text"
                      endIcon={<CopyAllOutlined fontSize="inherit" />}
                      size="small"
                      sx={({ typography }) => ({
                        ...typography.body1,
                      })}
                      onClick={() =>
                        handleCopyVerificationCode(verifyModal.verificationCode)
                      }
                    >
                      {verifyModal.verificationCode}
                    </Button>
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Access the DNS records for {verifyModal.domain}.
                  </ListItemText>
                  <Alert severity="warning">
                    <Typography>
                      Accessing your domain's DNS records may require a domain
                      administrator from your IT support to assist you in the
                      following steps.
                    </Typography>
                  </Alert>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Create a TXT record for your domain with a name of
                    '_sooth_verify'.
                  </ListItemText>
                  <ListItemText>
                    Example: Your full TXT record name should look like
                    _sooth_verify.
                    {verifyModal.domain} when saved.
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Set the value of the TXT record to the verification code you
                    copied above.
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <ListItemText>
                    Set the TTL (Time to live) value of the TXT record to 60
                    seconds.
                  </ListItemText>
                </ListItem>
              </List>
              <Alert severity="info">
                <Typography>
                  We check for your verification code every 30 minutes. However,
                  DNS records can take up to 48 hours to propogate. Your
                  verification status will be updated as soon as we can validate
                  the verification code in your DNS records. Please check back
                  on the Group Controls page to see if your domain has been
                  verified.
                </Typography>
              </Alert>
            </DialogContent>
            <DialogActions
              sx={({ palette }) => ({
                backgroundColor: palette.secondary.main,
              })}
            >
              <Button onClick={() => setVerifyModal(null)}>Close</Button>
            </DialogActions>
          </Dialog>
        )}
      </Stack>
      <Footer />
    </Box>
  );
}
