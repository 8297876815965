import dayjs from "lib/dayjs";
import {
  get,
  map,
  filter,
  reduce,
  some,
  find,
  orderBy,
  includes,
} from "lodash";
import * as yup from "yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Formik } from "formik";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import { AddOutlined } from "@mui/icons-material";

import FormikTextField from "components/base/FormikTextField";
import FormikSelectField from "components/base/FormikSelectField";
import licenseLevels from "features/Group/data/group-license-levels.json";
import { useGeoData } from "features/GeoData";

import { useAdmin } from "../hooks";

const PRIORITY_COUNTRIES = ["USA"];

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

export default function SetupGroup({ user, handleClose = () => {} }) {
  const { createGroup } = useAdmin();
  const { countries, activeRegions } = useGeoData();
  const formRef = useRef();

  const schema = yup.object().shape({
    organizationName: yup.string().max(75).required().label("Group Name"),
    email: yup.string().email().required().label("Email Address"),
    userId: yup.string().required(),
    fullName: yup.string().max(50).required().label("Contact Name"),
    referenceNo: yup.string().notRequired().max(50).label("P.O. Number"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Must be a valid Phone Number.")
      .max(15)
      .required()
      .label("Phone Number"),
    city: yup.string().required().max(75).label("City"),
    state: yup
      .string()
      .test(
        "valid-state",
        ({ label }) => `Must be a valid ${label}`,
        async (value, context) => {
          const { country } = context.from[0].value;
          const countryObj = find(countries, { iso3: country });
          if (countryObj != null) {
            return activeRegions.length > 1
              ? some(activeRegions, { state_code: value })
              : true;
          }
          return false;
        }
      )
      .label("State/Region"),
    country: yup
      .string()
      .test("valid-country", "Must be a valid Country", (value) => {
        return some(countries, { iso3: value });
      })
      .label("Country"),
    planTypeData: yup
      .array(
        yup.object({
          classGroupId: yup.string().required(),
          noOfLicenses: yup
            .number()
            .min(0)
            .integer()
            .label("Number of Licenses"),
        })
      )
      .test(
        "total-license-check",
        "You must assign 1 or more license",
        (value, context) => {
          const totalLicenses = reduce(
            value,
            (sum, n) => sum + n.noOfLicenses,
            0
          );
          return totalLicenses > 0;
        }
      ),
    expirationDate: yup
      .string()
      .notRequired()
      .test(
        "is-valid-expiration",
        "If set, you must set Plan Expiration Date at least 30 days in the future.",
        (value) => {
          if (value === null) {
            return true;
          }
          const date = dayjs(value);
          if (date.isValid() && date.isAfter(dayjs().add(29, "day"))) {
            return true;
          }
          return false;
        }
      )
      .label("Plan Expiration Date"),
  });

  const [loading, setLoading] = useState(false);
  const [subLevel, setSubLevel] = useState("k12");

  const planTypeOptions = useMemo(() => {
    return map(filter(licenseLevels, { groupKey: subLevel }), (level) => ({
      classGroupId: level.classGroupId,
      label: level.licenseLabel,
      noOfLicenses: 0,
    }));
  }, [subLevel]);

  const initialState = useMemo(
    () => ({
      organizationName: "",
      email: user?.email != null ? user.email : "",
      userId: user?.id != null ? user.id : "",
      fullName: "",
      referenceNo: "",
      phoneNumber: "",
      city: "",
      state: "",
      country: "USA",
      planTypeData: [...planTypeOptions],
      expirationDate: null,
    }),
    [user, planTypeOptions]
  );

  async function onFormSubmit({
    organizationName,
    email,
    userId,
    fullName,
    referenceNo,
    phoneNumber,
    city,
    state,
    country,
    planTypeData,
    expirationDate,
  }) {
    setLoading(true);
    const success = await createGroup(
      userId,
      organizationName,
      fullName,
      email,
      referenceNo,
      phoneNumber,
      city,
      state,
      country,
      planTypeData,
      expirationDate
    );
    if (success) {
      toast.success(`Group created for user: @${user.userName}<${user.email}>`);
      handleClose();
    }
    setLoading(false);
  }

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize={true}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={onFormSubmit}
      innerRef={formRef}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleReset,
        handleSubmit,
        isSubmitting,
        isValid,
        dirty,
        touched,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <SetupModal
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleReset={handleReset}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isValid={isValid}
            dirty={dirty}
            touched={touched}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            handleClose={handleClose}
            user={user}
            loading={loading}
            subLevel={subLevel}
            setSubLevel={setSubLevel}
          />
        );
      }}
    </Formik>
  );
}

function SetupModal({
  values,
  errors,
  handleChange,
  handleBlur,
  handleReset,
  handleSubmit,
  isSubmitting,
  isValid,
  dirty,
  touched,
  setFieldValue,
  setFieldTouched,
  handleClose,
  user,
  loading,
  subLevel,
  setSubLevel,
}) {
  const { countries, activeRegions, updateActiveRegions } = useGeoData();

  const [exDate, setExDate] = useState(null);

  const {
    email,
    fullName,
    organizationName,
    referenceNo,
    phoneNumber,
    city,
    state,
    country,
    planTypeData,
    expirationDate,
  } = values;

  function resetAndClose(event) {
    handleReset(event);
    handleClose();
  }

  function mapLicenseLevels(planType, index) {
    const name = `planTypeData[${index}].noOfLicenses`;
    return (
      <FormikTextField
        key={name}
        fullWidth
        error={get(errors, name)}
        touched={get(touched, name, null)}
        handleBlur={handleBlur}
        handleChange={handleChange}
        value={planType.noOfLicenses}
        label={planType.label}
        name={name}
        type="number"
      />
    );
  }

  const regions = useMemo(() => {
    return orderBy(
      map(activeRegions, (region) => ({
        key: region.state_code,
        label: region.name,
      })),
      ["label"]
    );
  }, [activeRegions]);

  useEffect(() => {
    async function fetchRegions() {
      const countryObj = find(countries, { iso3: country });
      if (countryObj != null) {
        await updateActiveRegions(countryObj.id);
      }
    }

    fetchRegions();
  }, [countries, country]);

  useEffect(() => {
    setTimeout(() => {
      if (!some(activeRegions, { state_code: state })) {
        setFieldValue("state", "");
        setFieldTouched("state", false);
      }
    }, 0);
  }, [regions, activeRegions, country, state, setFieldTouched, setFieldValue]);

  useEffect(() => {
    setFieldTouched("expirationDate");
    if (exDate == null) {
      setFieldValue("expirationDate", null, true);
    } else {
      setFieldValue("expirationDate", exDate.utc().format());
    }
  }, [exDate, setFieldValue, setFieldTouched]);

  useEffect(() => {
    setExDate(expirationDate == null ? null : dayjs(expirationDate));
  }, [expirationDate]);

  return (
    <Dialog
      open={user != null}
      onClose={resetAndClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent
        sx={{
          overflowY: "revert",
        }}
      >
        <Stack direction="row" gap={4}>
          <Stack direction="column" sx={{ flex: 5 }}>
            <Typography variant="subtitle1">Group Details</Typography>
            <FormikTextField
              fullWidth
              error={get(errors, "organizationName")}
              touched={get(touched, "organizationName", null)}
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={organizationName}
              label="Group Name"
              name="organizationName"
            />
            <FormikTextField
              fullWidth
              error={get(errors, "referenceNo")}
              touched={get(touched, "referenceNo", null)}
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={referenceNo}
              label="P.O. Number"
              name="referenceNo"
            />
            <Typography variant="subtitle1">Contact Information</Typography>
            <Typography variant="caption">
              This is the primary contact should Sooth.fyi need to contact your
              organization about your group account.
            </Typography>
            <FormikTextField
              fullWidth
              error={get(errors, "fullName")}
              touched={get(touched, "fullName", null)}
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={fullName}
              label="Contact Name"
              name="fullName"
            />
            <FormikTextField
              fullWidth
              error={get(errors, "email")}
              touched={get(touched, "email", null)}
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={email}
              label="Email Address"
              name="email"
            />
            <FormikTextField
              fullWidth
              error={get(errors, "phoneNumber")}
              touched={get(touched, "phoneNumber", null)}
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={phoneNumber}
              label="Phone Number"
              name="phoneNumber"
            />
            <FormikSelectField
              error={get(errors, "country")}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={get(touched, "country", null)}
              size="small"
              value={country}
              label="Country"
              name="country"
              id="country-select"
              options={map(countries, (country) => ({
                key: country.iso3,
                label: `${country.emoji} ${country.name} ${
                  country.native != null &&
                  country.name.toLowerCase() !== country.native.toLowerCase()
                    ? `(${country.native})`
                    : ""
                }`,
                default: country.iso3 === "USA",
              })).sort((countryA, countryB) => {
                const aPriority = includes(PRIORITY_COUNTRIES, countryA.key);
                const bPriority = includes(PRIORITY_COUNTRIES, countryB.key);
                if (aPriority && bPriority) {
                  return countryA.name - countryB.name;
                }
                if (aPriority) {
                  return -1;
                }
                if (bPriority) {
                  return 1;
                }
                return countryA.name - countryB.name;
              })}
            />

            <FormikTextField
              fullWidth
              error={get(errors, "city")}
              touched={get(touched, "city", null)}
              handleBlur={handleBlur}
              handleChange={handleChange}
              value={city}
              label="City"
              name="city"
            />
            <FormikSelectField
              error={get(errors, "state")}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={get(touched, "state", null)}
              value={state}
              label="State/Region"
              name="state"
              id="state-select"
              options={regions}
              size="small"
              sx={{
                flex: 1,
              }}
              disabled={regions.length < 1}
              helperText={
                regions.length > 1 ? null : "Country has no states/regions"
              }
            />
          </Stack>
          <Stack direction="column" sx={{ flex: 7 }}>
            <Typography variant="subtitle1">Licenses</Typography>
            <ToggleButtonGroup
              fullWidth
              value={subLevel}
              onChange={(event, value) => setSubLevel(value)}
              exclusive
            >
              <ToggleButton value="k12">K-12</ToggleButton>
              <ToggleButton value="highEd">Higher Education</ToggleButton>
            </ToggleButtonGroup>
            {typeof get(errors, "planTypeData") === "string" &&
              !!get(touched, "planTypeData", false) && (
                <Alert variant="outlined" severity="error">
                  {`${get(errors, "planTypeData")}`}
                </Alert>
              )}
            {planTypeData.map(mapLicenseLevels)}
            <Typography variant="subtitle1">Plan Details</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Plan Expiration Date"
                onChange={(value) => {
                  setExDate(value);
                }}
                onBlur={() => {
                  console.log("I AM BLURRY");
                }}
                value={exDate}
                minDate={dayjs().add(30, "day")}
                disablePast
                slotProps={{
                  field: {
                    clearable: true,
                  },
                  textField: {
                    error:
                      !!get(touched, "expirationDate", false) &&
                      !!get(errors, "expirationDate"),
                    helperText:
                      !!get(touched, "expirationDate", false) &&
                      get(errors, "expirationDate"),
                  },
                }}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "secondary.main",
        }}
      >
        <Button variant="outlined" onClick={resetAndClose}>
          Cancel
        </Button>
        <LoadingButton
          startIcon={<AddOutlined />}
          loading={isSubmitting || loading}
          variant="contained"
          type="submit"
          disabled={!dirty || !isValid}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
